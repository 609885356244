import { FC, useMemo, Fragment } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
// sections
import Intro from './sections/Intro';
import Role from './sections/Role';
import TechStack from './sections/TechStack';
import WorkInNumbers from './sections/WorkInNumbers';
import Pages from './sections/Pages';
import Features from './sections/Features';
import Feedback from './sections/Feedback';
import DraggableScreens from './sections/DraggableScreens';
import Links from './sections/Links';
import MoreWork from 'src/pages/project/sections/MoreWork';
import ProductBlock from 'src/pages/project/sections/ProductBlock';
// import VideoDemo from 'src/pages/project/sections/VideoDemo';
// hooks
import useMobileData from 'src/cms/data/project/useMobileData';
import useAppData from 'src/cms/data/project/useApp#Data';
import useAppWorkflowData from 'src/cms/data/project/useAppWorkflowData';
import usePagesData from 'src/cms/data/project/usePagesData';
import useVideoUXData from 'src/cms/data/project/useVideoUXData';
import useVideoMobileUXData from 'src/cms/data/project/useVideoMobileUXData';
import useWebsiteData from 'src/cms/data/project/useWebsiteData';
import useWindowSize from 'src/hooks/useWindowSize';
// types
import type { PageProps } from 'gatsby';
import type { PortfolioProject } from 'src/cms/data/home/usePortfolioData';
import { MetaData } from 'src/types/metaData.types';

const Project: FC<PageProps<{}, { project: PortfolioProject }>> = ({
  location,
  pageContext: { project },
}) => {
  // hooks
  const mobileData = useMobileData();
  const appData = useAppData();
  const appWorkflowData = useAppWorkflowData();
  const pagesData = usePagesData();
  const videoUXData = useVideoUXData();
  const videoMobileUXData = useVideoMobileUXData();
  const websiteData = useWebsiteData();
  const { isMobile } = useWindowSize();

  if (!project) {
    return null;
  }

  const metaData: MetaData = useMemo(() => {
    return {
      title: `Seedium | ${project.title}`,
      description: project.description,
    };
  }, [project]);

  return (
    <PageLayout metaData={{ ...metaData, href: location.href }}>
      <Intro
        title={project.title}
        description={project.description}
        roles={project.roles}
        color={project.frameColor}
        image={project.image}
      />
      <Role
        role={project.role}
        projects={project.projects}
        service={project.service}
        specs={project.specs}
        clientLocation={project.clientLocation}
        timeframe={project.timeframe}
        links={project.links}
      />
      <TechStack technologies={project.technologies} />
      {/* todo return presentation fields when presentation will be available*/}
      {/* {project.presentation && project.presentation.title && ( */}
      {/*  <VideoDemo*/}
      {/*    title={project.presentation.title}*/}
      {/*    description={project.presentation.description}*/}
      {/*    image={project.presentation.image}*/}
      {/*    video={project.presentation.video}*/}
      {/*  />*/}
      {/* )}*/}
      {project.sections?.map((section, index) => (
        <Fragment key={index}>
          <Pages
            title={section.title}
            pages={section.images}
            isHeader={!isMobile}
            variantPagination='dotsWithoutNumber'
          />
        </Fragment>
      ))}
      {project.features?.map((feature, index) => (
        <ProductBlock
          key={index}
          title={feature.title}
          description={feature.description}
          isHeader={feature.header}
          image={feature.image}
          reversed={index % 2 === 1}
        />
      ))}
      {project.appWorkflow?.length && (
        <Pages
          title={appWorkflowData.title}
          pages={project.appWorkflow}
          isHeader={!isMobile}
          variantPagination='dotsWithoutNumber'
        />
      )}
      {project.videoUX?.length && (
        <Pages
          title={videoUXData.title}
          pages={project.videoUX}
          isHeader={!isMobile}
          variantPagination='dotsWithoutNumber'
        />
      )}
      <WorkInNumbers items={project.workInNumbers} />
      {project.videoMobileUX?.length && (
        <DraggableScreens
          items={project.videoMobileUX}
          title={videoMobileUXData.title}
          isWebsites
          variantPagination='dotsWithoutNumber'
        />
      )}
      {project.pages?.length && (
        <Pages
          title={pagesData.title}
          pages={project.pages}
          isHeader
          variantPagination='dots'
        />
      )}
      {project.website?.length && (
        <Pages
          title={websiteData.title}
          pages={project.website}
          isHeader={!isMobile}
          variantPagination='dotsWithoutNumber'
        />
      )}
      <DraggableScreens
        items={project.mobile}
        title={mobileData.title}
        isWebsites
        variantPagination='dotsWithoutNumber'
      />
      <DraggableScreens
        items={project.app}
        title={appData.title}
        isWebsites={false}
        variantPagination='dotsWithoutNumber'
      />
      <Features features={project.featuresList} />
      <Feedback projectFeedback={project.projectFeedback} />
      <Links links={project.siteLinks} />
      <MoreWork title={project.title} />
    </PageLayout>
  );
};

export default Project;
