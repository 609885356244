import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
// components
import Section from 'src/components/Section';
import Pagination from 'src/components/Pagination';
import DraggableScroll from 'src/components/DraggableScroll';
import WebsitePage from 'src/components/WebsitePage';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';

import * as style from './DraggableScreens.module.scss';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { PaginationVariant } from 'src/components/Pagination/Pagination.interface';

type Props = {
  title: string;
  isWebsites: boolean;
  variantPagination: PaginationVariant;
  items: {
    image: IGatsbyImageData;
  }[];
};

const defaultSlideWidth = 275;

const DraggableScreens: FC<Props> = ({
  title,
  isWebsites,
  items,
  variantPagination,
}) => {
  const { isMobile } = useWindowSize();
  // state
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [slideWidth, setSlideWidth] = useState<number>(defaultSlideWidth);
  // refs
  const sliderRef = useRef<Slider>(null);
  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveSlide(nextSlide);
      },
    }),
    []
  );

  useEffect(() => {
    if (isMobile && typeof document !== 'undefined') {
      setSlideWidth(document.body.clientWidth);
    } else {
      setSlideWidth(defaultSlideWidth);
    }
  }, [isMobile]);

  const handlePageClick = useCallback((slideIndex: number) => {
    sliderRef.current?.slickGoTo(slideIndex);
  }, []);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Section title={title} className={style.section}>
      {isMobile ? (
        <>
          <Slider className={style.slider} ref={sliderRef} {...sliderSettings}>
            {items.map((item, i) => (
              <div
                className={style.slide}
                key={i}
                style={{ width: slideWidth }}
              >
                <WebsitePage
                  image={item.image}
                  ratioSize={186}
                  isHeader={isWebsites}
                />
              </div>
            ))}
          </Slider>
          <div className={style.pagination}>
            <Pagination
              variant={variantPagination}
              itemsCount={items.length}
              activeItem={activeSlide}
              onClick={handlePageClick}
            />
          </div>
        </>
      ) : (
        <DraggableScroll>
          {items.map((item, i) => (
            <div className={style.slide} key={i} style={{ width: slideWidth }}>
              <WebsitePage
                image={item.image}
                ratioSize={186}
                isHeader={isWebsites}
              />
            </div>
          ))}
        </DraggableScroll>
      )}
    </Section>
  );
};

export default DraggableScreens;
