// extracted by mini-css-extract-plugin
export var image = "Role-module--image--32196";
export var line = "Role-module--line--99720";
export var links = "Role-module--links--fdcb5";
export var project = "Role-module--project--da557";
export var root = "Role-module--root--f924a";
export var rowDescription = "Role-module--rowDescription--c7f5b";
export var rowTitle = "Role-module--rowTitle--a263f";
export var text = "Role-module--text--6fa39";
export var textInfo = "Role-module--textInfo--29f04";
export var title = "Role-module--title--824ab";