// extracted by mini-css-extract-plugin
export var description = "WorkInNumbers-module--description--f4e48";
export var item = "WorkInNumbers-module--item--6aebd";
export var number = "WorkInNumbers-module--number--410a4";
export var numberWrapper = "WorkInNumbers-module--numberWrapper--2873f";
export var percentageBlock = "WorkInNumbers-module--percentageBlock--d7248";
export var percentageDesription = "WorkInNumbers-module--percentageDesription--bbd40";
export var percentageNumber = "WorkInNumbers-module--percentageNumber--1da85";
export var progress = "WorkInNumbers-module--progress--02a4f";
export var root = "WorkInNumbers-module--root--ae7af";
export var scale = "WorkInNumbers-module--scale--f88de";
export var textAnimated = "WorkInNumbers-module--textAnimated--cee4b";